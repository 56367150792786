









































































import {Vue, Component} from 'vue-property-decorator';
import { checkAreaCode, checkNickname, checkPassword, regionPhoneList } from '../utils'
import Copyright from '../components/copyright.vue'
import { getVerification, forgetPassword } from '@/api/user_api';
@Component({
  components: {
    Copyright
  }
})
export default class Register extends Vue{
  regionPhoneList = regionPhoneList;
  form: {[key:string]: any} = {
    phoneRegion: {
      value: '',
    },
    phone: {
      value: '',
      showError: false,
      tip:'',
    },
    code: {
      value: '',
      showError: false,
      tip:'',
    },
    password: {
      value: '',
      showError: false,
      tip: '',

    },
    confirmPassword: {
      value: '',
      tip: '',
      showError: false,
    },
  }
  time = 0;
  interval:number = 0;
  async getCode(){
    if ( this.time > 0 ) return;
    if (!this.validatePhone()) {
      return;
    }
    try {
      await getVerification({
        mobileAreaCode: this.form.phoneRegion.value,
        mobile: this.form.phone.value,
        fkPlatformType: 'get_ib',
        type: 'reset',
      })
      this.time = 60;
      this.interval = setInterval(()=>{
        this.time --;
        if(this.time == 0){
          clearInterval(this.interval)
          return;
        }
      }, 1000)
    } catch (e) {
    }
  }
  resetValidate(key: string){
    this.form[key].showError = false;
    this.form[key].tip = '';
  }
  resetAllValidate(){
    for(let key in this.form){
      this.form[key].showError = false;
      this.form[key].tip = '';
    }
  }
  // 检查密码
  handleCheckPassWord(){
    if(!checkPassword(this.form.password.value)){
      this.form.password.showError = true;
      this.form.password.tip = this.$t('register_login_tip')+'';
      return true;
    }
    return false;
  }
  // 确认密码
  handleCheckConfirmPassword(){
    if(this.form.confirmPassword.value != this.form.password.value){
      this.form.confirmPassword.showError = true;
      this.form.confirmPassword.tip = this.$t('two_password_not_equal');
      return true;
    }
    return false;
  }
  validatePhone(){
    this.form.phone.tip = '';
    this.form.phoneRegion.tip = '';
    let isPass = true;
    if ( !this.form.phoneRegion.value ){
      this.form.phone.showError = true;
      this.form.phone.tip = this.$t('pleaseSelectPhoneRegion')+'';
      isPass = false;
    }
    if (!this.form.phone.value){
      this.form.phone.showError = true;
      isPass = false;
      if(!this.form.phone.tip) this.form.phone.tip = this.$t('pleaseInputPhoneNum') + '';
    }
    if (!checkAreaCode(this.form.phone.value, this.form.phoneRegion.value)){
      this.form.phone.showError = true;
      isPass = false;
      if(!this.form.phone.tip) this.form.phone.tip = this.$t('errorPhoneNumberInRegion') + '';
    }
    return isPass;
  }
  async submit(){
    this.resetAllValidate();
    let isPass = true;
    if(!this.validatePhone()){
      isPass = false;
    }
    if(!this.form.code.value){
      this.form.code.showError = true;
      this.form.code.tip = this.$t('pleaseInputCode')+'';
      isPass = false;
    }
    if(!this.form.password.value){
      this.form.password.showError = true;
      this.form.password.tip = this.$t('pleaseInputPwd')+'';
      isPass = false;
    }
    if(this.handleCheckPassWord()){
      isPass = false;
    }
    if(this.handleCheckConfirmPassword()){
      isPass = false;
    }
    if(!isPass) return;
    try {
      const ret = await forgetPassword({
        mobileAreaCode: this.form.phoneRegion.value,
        mobile: this.form.phone.value,
        verification: this.form.code.value,
        newLoginPs: this.form.password.value,
        fkPlatformType: "get_ib",
      })
      if(ret.data.code == 1003){
        this.$message.success(this.$t('sthSuccess', {sth: this.$t('changePassword')} )+'');
        this.form.phoneRegion.value = ''
        this.form.phone.value = ''
        this.form.code.value = ''
        this.form.password.value = ''
        this.form.confirmPassword.value = ''
        this.$router.push('/login')
      }
    } catch (e) {
    }
  }
}
